> .layout-navbar {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  > ul {
    height: 60px;
    align-items: center;
    border-radius: 0.3rem;
    border: $border1;
    display: flex;
    justify-content: space-between;
    list-style: none;
    margin: 0;
    padding-left: 0;
    padding: 0.7rem;
    width: 100%;
    > a {
      color: var(--darkcolor);
      font-size: 0.8rem !important;
      font-size: 1.2rem;
      list-style: none;
      padding: 0.2rem 0.5rem;
      text-decoration: none;
      transition: 0.3s all linear;
      &.active,
      &:hover {
        background: $dark2;
        border-radius: 0.3rem;
        color: $white;
        border: none;
      }
    }
  }
}
