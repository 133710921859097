.ot_row_options {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  gap: 3px;
  flex-wrap: wrap;
}

.ot_row_options .ot_row_button {
  border: none;
  width: 20px;
  height: 20px;
  border-radius: 3px;
  color: #fff;
  transition: 0.2s all ease-in-out;
}
.ot_row_options .ot_row_button:hover {
  box-shadow: var(--small-shadow);
}
.red1 {
  background-color: var(--redcolor) !important;
}
.red2 {
  background-color: var(--crimson) !important;
}
.red3 {
  background-color: var(--softred) !important;
}
.red4 {
  background-color: var(--palered) !important;
}
.blue1 {
  background-color: var(--blue1) !important;
}
.blue2 {
  background-color: var(--blue2) !important;
}
.blue3 {
  background-color: var(--greyblue) !important;
}
.blue4 {
  background-color: var(--darkcolor) !important;
}
.green {
  background-color: var(--thirdcolor) !important;
}
.lilly {
  background-color: var(--lillycolor) !important;
}
.yellow1 {
  background-color: var(--yellow1) !important;
}
.yellow2 {
  background-color: var(--ocre) !important;
}
.yellow3 {
  background-color: var(--orangecolor) !important;
}
.yellow4 {
  background-color: var(--orangecolor2) !important;
}

.text-white {
  color: #fff;
}

.ot_row_options .ot_row_button > i {
  font-size: .6rem;
}
