.seccion-detalle-datos-info.ot > div {
  margin-bottom: 5px !important;
}

.seccion-detalle-datos-info.ot > div.bigtext {
  flex: 0 0 100% !important;
}

.seccion-detalle-datos-info.ot > div.subtitle {
  flex: 0 0 100% !important;
  text-transform: uppercase;
  margin-bottom: 0;
}

.seccion-detalle-datos-info.ot > div.subtitle > p {
  font-size: 1.2rem !important;
}
.seccion-detalle-datos-info.ot > div.subtitle:not(.subtitle:first-child) {
  border-top: 1px solid var(--darkcolor_soft);
  padding-top: 0.5rem;
}

.seccion-detalle-datos-info.ot > div {
  min-width: unset !important;
  flex: 0 0 23%;
  margin-right: 10px;
}

.seccion-detalle-datos-info.ot > div.bigtext {
  flex: 0 0 48% !important;
}

div.bigtext .bigtext_observations {
  display: block;
  margin-left: 10px;
  background-color: var(--input-border);
  padding: 0.3rem;
  border-radius: 0.3rem;
}

div.bigtext .bigtext_observations span {
  display: block;
}
