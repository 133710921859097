.panel_cliente_crear_ticket.ot > form > div {
  border: none !important;
}

.ot_search_form {
  border: 1px solid var(--input-border);
  padding: 0.7rem 0;
  border-top: none;
}

.ot_status {
  align-items: flex-start;
  border-radius: 0.2rem;
  border: 1px solid var(--input-border);
  display: flex;
  flex-direction: column;
  height: 120px;
  justify-content: left;
  overflow-y: auto;
  padding: 0.7rem 0.3rem;
}

.ot_status li {
  align-items: center;
  border-radius: 0.3rem;
  border: 1px solid var(--input-border);
  display: flex;
  flex: 0 0 1;
  font-size: 0.6rem;
  justify-content: space-between;
  margin-bottom: 0.3rem;
  padding: 0.3rem;
  transition: 0.2s all ease-in-out;
  width: 100%;
}
.ot_status li:hover {
  border: 1px solid var(--green);
  box-shadow: var(--small-shadow);
  cursor: pointer;
}
.ot_status li:active {
  background-color: var(--input-border);
}
.ot_status li p {
  margin: 0;
}
.ot_status li i {
  color: var(--input-border);
  transition: 0.2s all ease-in-out;
}
.ot_status li:active i,
.ot_status li:hover i {
  color: var(--green);
}

.ot_status li span:first-child {
  flex: 0 0 30%;
}

.ot_status_shorter_status {
  width: 20%;
  text-overflow: ellipsis !important;
  overflow: hidden;
  word-break: break-word;
  white-space: nowrap;
}

.ot_button_group_1 {
  justify-content: space-between;
  padding-top: 0 !important;
  display: flex;
}

.ot_button_group_1 button {
  align-items: center;
  border-radius: 0.3rem;
  color: #fff;
  display: flex;
  flex: 0 0 calc(50% - 5px);
  font-size: 1rem;
  justify-content: center;
  padding: 5px 15px;
}

.ot_button_group_1 .ot_create_button {
  transition: 0.3s all linear;
  border: none;
}

.ot_button_group_1 button.ot_search_button {
  transition: 0.3s all linear;
  border: none;
}

.ot_button_group_1 button.ot_search_button > small > i {
  margin-right: 0.5rem;
}

.ot_button_group_1 button:hover {
  box-shadow: var(--shadow);
}

@import url("./OTTabIcons.css");
