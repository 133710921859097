[id^="controlled-ot-tabs-tab-"] {
  padding: 0px;
}
[id^="controlled-ot-tabs-tab-"].active .ot_tab_icon {
  color: var(--darkcolor);
}

.ot_tab_icon {
  font-size: 1rem;
  color: var(--darkcolor_soft);
  padding: 3px 9px;
  border-radius: 3px;
  margin: 1px;
  transition: 0.25s all ease-in-out;
}

[id^="controlled-ot-tabs-tab-"].active .ot_tab_icon {
}

.ot_tab_icon:hover {
  color: var(--darkcolor);
  background-color: var(--input-border);
}
