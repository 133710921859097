.ot_massive_distribution {
  border-radius: 0.3rem;
  border: 1px solid var(--input-border);
  padding: 0.7rem;
}

.ot_massive_distribution > p {
  color: var(--darkcolor);
}

/*///////////////////////////////////////
////////////////////////////////////////
///////////////////////////////////////*/

.ot_distribution_top {
  justify-content: space-between;
  display: flex;
  align-items: flex-start;
}

.ot_distribution_text,
.ot_distribution_options {
}

.ot_distribution_text {
  width: calc(75% - 30px);
}

.ot_distribution_options {
  width: 25%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.ot_distribution_top > p {
  margin-bottom: 0;
  font-size: 1rem;
}
.ot_distribution_send,
.ot_distribution_select {
  display: flex;
  justify-content: flex-end;
}

.ot_distribution_select {
  flex: 0 0 55%;
}

.ot_distribution_select select {
  width: 100%;
  height: 100%;
  padding: 5px;
  margin: 5px;
  margin-right: 0 !important;
}

.ot_distribution_send > button {
  display: inline-block;
  border-radius: 0.2rem;
  background-color: var(--blue1);
  border: none;
  color: #ffffff;
  text-align: center;
  font-size: 14px;
  padding: 5px;
  width: 80px;
  height: 100%;
  transition: all 0.5s;
  cursor: pointer;
  margin: 5px;
  margin-right: 0 !important;
}

.ot_distribution_send > button span {
  cursor: pointer;
  display: inline-block;
  position: relative;
  transition: 0.5s;
}
.ot_distribution_send > button span::after {
  content: "\00bb";
  position: absolute;
  opacity: 0;
  top: 0;
  right: -20px;
  transition: 0.5s;
}
.ot_distribution_send > button:hover span {
  padding-right: 15px;
}
.ot_distribution_send > button:hover span::after {
  opacity: 1;
  right: 0;
}
.ot_distribution_send > button:hover {
  background: var(--darkcolor);
  box-shadow: var(--small-shadow);
}

/*///////////////////////////////////////
////////////////////////////////////////
///////////////////////////////////////*/

div.ot_distribution_dnd_group {
  align-items: flex-start;
  display: flex;
  justify-content: space-between;
  height: 400px;
  position: relative;
}

div.ot_distribution_dnd_group > i {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 2rem;
  color: var(--input-border);
}

.ot_distribution_dnd_start_group,
.ot_distribution_dnd_finish_group {
  display: flex;
  flex-direction: column;
  flex: 0 0 calc(50% - 30px);
  height: 100%;
  justify-content: flex-end;
}

.ot_distribution_dnd_start,
.ot_distribution_dnd_finish {
  border-radius: 0.3rem;
  border: 1px solid var(--input-border);
  padding: 0.7rem;
  overflow-y: auto;
  height: 100%;
  flex: 0 0 1;
}

.ot_drop_to {
  height: 100%;
  padding: 0 !important;
  border-radius: 0.3rem;
}

.ot_distribution_destination input {
  border-radius: 0.3rem;
  border: 1px solid var(--input-border);
  padding: 0.3rem;
  margin-bottom: 10px;
  transition: 0.25s all ease-in-out;
}

.ot_distribution_destination input:hover {
  border: 1px solid var(--darkcolor);
}

.ot_distribution_destination.filter-ot {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  margin-bottom: 10px;
  justify-content: space-between !important;
}

.ot_distribution_destination.filter-ot > .aux_container {
  display: flex;
  flex-direction: column;
  flex: 0 0 calc(65% - 15px);
  width: calc(65% - 15px);
}

.ot_distribution_destination.filter-ot > .aux_container > input {
  margin-bottom: 0;
}
.ot_distribution_destination.filter-ot > .filter-button {
  flex: 0 0 25%;
  width: 25%;
  height: 2rem;
  border: none;
  border-radius: 0.3rem;
  position: relative;
  text-align: left;
  padding-left: 30px;
  transition: 0.4s all ease-in-out;
  color: #fff;
}
.filter-ot > .filter-button span {
  margin-right: 5px;
  transition: 0.4s all ease-in-out;
}
.filter-ot > .filter-button:hover {
  padding-left: 20px;
}

.filter-ot > .filter-button i {
  position: absolute;
  right: 0px;
  top: 50%;
  opacity: 0;
  transform: translate(0, -50%);
  transition: 0.4s all ease-in-out;
}

.filter-ot > .filter-button:hover i {
  right: 20px;
  opacity: 1;
}

.ot_distribution_destination.filter-ot > .erase-button {
  border-radius: 0.3rem;
  border: none;
  color: #fff;
  flex: 0 0 10%;
  height: 2rem;
  position: relative;
  transition: 0.4s all ease-in-out;
  width: 10%;
}

.ot_distribution_dnd_group ul {
  display: flex;
  flex-direction: column;
  padding-left: 0.7rem;
  list-style: none;
}
.ot_distribution_dnd_group ul li {
  padding: 0.3rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #fff;
  border-radius: 0.3rem;
  border: 1px solid var(--input-border);
  margin-bottom: 0.3rem;
  transition: 0.2s all ease-in-out;
}
.ot_distribution_dnd_group ul li:hover {
  cursor: grab;
}
.ot_distribution_dnd_group ul li:active {
  cursor: grabbing;
  background-color: var(--input-border);
}
.ot_distribution_dnd_group ul li p {
  margin: 0;
}
.ot_distribution_dnd_group ul li i {
  color: var(--input-border);
}
.ot_distribution_dnd_group ul li:active i {
  color: var(--darkcolor);
}

.ot_distribution_destination {
  flex: 0 0 1;
  display: flex;
  flex-direction: column;
}
.ot_distribution_destination input {
  border-radius: 0.3rem;
  border: 1px solid var(--input-border);
  padding: 0.3rem;
}
