.ot_confirmation_top {
  border-bottom: unset;
}
.ot_confirmation_body {
  border-radius: 0.3rem;
  border: 1px solid var(--input-border);
  margin: 1rem;
  padding: 1rem;
}

.ot_confirmation_body .ot_pdf_viewer {
  overflow: scroll;
  max-height: 20vw;
}

.ot_confirmation_bottom {
  border-top: unset;
}

/* ESTILOS PARA MODAL DE CONFIRMACIÓN DE PRESUPUESTO PARA RMA */

.ot_confirm_rma_budget {
  display: flex;
  flex-direction: column;
}

/* div.ot_confirm_rma_budget > small {
  display: block;
  width: 100%;
} */

.radio_button_container {
  border-radius: 0.3rem;
  border: 1px solid var(--input-border);
  display: flex;
  justify-content: space-between;
  margin: 10px 0;
  padding: 0.7rem 0.3rem 0;
  transition: 0.4s all ease-in-out;
  width: 100%;
}

.radio_button_container:hover,
.radio_button_container:active,
.radio_button_container:focus {
  border: 1px solid var(--darkcolor);
}

.radio_button_container label {
  border-radius: 0.3rem;
  flex: 0 0 49%;
  padding-left: 0.5rem;
  transition: 0.4s all ease-in-out;
}

.radio_button_container label:hover {
  background-color: var(--input-border);
  cursor: pointer;
}

.radio_button_container label input {
  margin-right: 10px;
  margin-left: auto;
}

.ot_confirm_rma_budget_comment {
  width: 100%;
}

.ot_confirm_rma_budget_comment textarea {
  border-radius: 0.3rem;
  border: 1px solid var(--input-border);
  outline: none;
  padding: 0.7rem;
  resize: unset;
  transition: 0.4s all ease-in-out;
  width: 100%;
}

.ot_confirm_rma_budget_comment textarea:hover,
.ot_confirm_rma_budget_comment textarea:active,
.ot_confirm_rma_budget_comment textarea:focus {
  border: 1px solid var(--darkcolor);
}
