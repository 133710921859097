.panel_cliente_izquierda.ot {
  width: 25%;
}
.panel_cliente_derecha.ot {
  width: 74%;
}

ul#ot_navbar {
  height: 100%;
}

ul#ot_navbar > a {
  font-size: 0.8rem !important;
}

.panel_cliente_tipo_ticket > #ot_navbar {
  justify-content: space-evenly;
}

@import url("./OTDistribution/OTDistributionPanel.css");
@import url("./OTExport/OTPDF.css");
@import url("./OTUpdates/OTUpdatesModal.css");
