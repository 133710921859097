:root {
  --darkcolor: rgba(15, 39, 59, 1);
  --darkcolor_soft: rgba(15, 39, 59, 0.5);
  --blue1: #2e86ab;
  --midcolor: rgba(109, 150, 247, 1);
  --blue2: #276fbf;
  --greyblue: #6c698d;
  --softcolor: rgb(228, 242, 255);
  --purplecolor: rgba(114, 107, 121, 0.3);
  --input-border: rgba(192, 192, 192, 0.5);
  --ltcolor: rgba(247, 248, 249, 1);
  --yellow1: #f5f749;
  --ocre: #ecd444;
  --orangecolor: rgb(255, 217, 0);
  --orangecolor2: rgb(255, 174, 0);
  --redcolor: rgb(238, 27, 34);
  --crimson: #f03a47;
  --softred: #f24236;
  --palered: #af5b5b;
  --thirdcolor: rgb(82, 196, 82);
  --fourthcolor: rgb(97, 151, 252);
  --lillycolor: rgb(203, 130, 252);
  --shadow: 1px 1px 7px 1px rgba(0, 0, 0, 0.2),
    1px 1px 3px 1px rgba(0, 0, 0, 0.1);
  --small-shadow: 1px 1px 2px 1px rgba(0, 0, 0, 0.2),
    1px 1px 3px rgba(0, 0, 0, 0.05);
}

html {
  box-sizing: border-box;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

body {
  background: var(--midcolor) !important;
  padding: 0;
  margin: 0;
  height: 85%;
  font-family: "Nunito", sans-serif !important;
}
