$dark: rgba(15, 39, 59, 1);
$dark2: rgba(15, 39, 59, 0.5);
$dark3: rgba(114, 107, 121, 0.3);

$blue1: #2e86ab;
$blue2: #276fbf;
$blue3: rgba(109, 150, 247, 1);
$blue4: rgb(97, 151, 252);

$grey1: #6c698d;
$grey3: rgb(228, 242, 255);
$grey4: rgba(247, 248, 249, 1);
$grey2: rgba(192, 192, 192, 0.5);

$yellow1: #f5f749;
$yellow2: #ecd444;
$yellow3: rgb(255, 217, 0);
$yellow4: rgb(255, 174, 0);

$red1: rgb(238, 27, 34);
$red2: #f03a47;
$red3: #f24236;
$red4: #af5b5b;

$green: rgb(82, 196, 82);
$purple: rgb(203, 130, 252);

$white: #fff;

$shadow: 1px 1px 7px 1px rgba(0, 0, 0, 0.2), 1px 1px 3px 1px rgba(0, 0, 0, 0.1);
$small-shadow: 1px 1px 2px 1px rgba(0, 0, 0, 0.2),
  1px 1px 3px rgba(0, 0, 0, 0.05);

$border1: 1px solid $grey2;

$generic-transition: 0.3s all ease-in-out;
