.panel-general {
  height: 100vh;
  > .panel-general-container {
    margin: 1.2rem;
    margin-left: 5rem;
    max-width: 93vw;
    padding: 1rem;
    background: white;
    border-radius: 0.5rem;
    font-size: 0.7rem;
    height: calc(100vh - 2.4rem);
    box-shadow: var(--shadow);
    > .panel-general-inner {
      height: 100%;

      > .layout-inner {
        display: flex;
        height: 100%;
        > [class^="layout-inner-"] {
          margin: 0 1rem;
          height: 100%;
        }

        .layout-inner-left {
          width: 25%;
          margin: 0 1rem;
        }

        .layout-inner-right {
          width: 74%;
          margin: 0 1rem;

          > .layout-navbar {
            height: 60px;
            display: flex;
            align-items: center;
            margin-bottom: 1rem;
            > ul {
              align-items: center;
              border-radius: 0.3rem;
              border: $border1;
              display: flex;
              justify-content: space-between;
              list-style: none;
              margin: 0;
              padding-left: 0;
              padding: 0.7rem;
              width: 100%;
              > a {
                color: var(--darkcolor);
                font-size: 0.8rem !important;
                font-size: 1.2rem;
                list-style: none;
                padding: 0.2rem 0.5rem;
                text-decoration: none;
                transition: 0.3s all linear;
                &.active,
                &:hover {
                  background: $dark2;
                  border-radius: 0.3rem;
                  color: $white;
                }
              }
            }
          }
          > .layout-chart,
          > .layout-screen {
            height: calc(100% - (60px + 1rem));
          }
        }

        > .layout-inner-wrapper {
          flex: 0 0 100%;
          display: flex;
          width: 100%;
          margin: 0;
        }
      }
    }
  }
}
