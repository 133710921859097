.layout-inner {
  display: flex;
  padding: 0.1rem;
  height: 100%;

  .layout-inner-left {
    width: 25%;
    margin: 0 1rem;
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
    @import "../components/layout-filter/styles/";
    @import "../components/layout-forms/styles/";
  }

  .layout-inner-right {
    width: 74%;
    margin: 0 1rem;
    height: 100%;

    @import "../components/layout-navbar/styles/";
    @import "../components/layout-chart/styles/";
  }
}
