.layout-forms {
  flex: 0 0 100%;
  margin-top: 10px;
  > .nav-item {
    padding: 0;
    &.active {
      > .tab-icon {
        color: $dark;
      }
    }
    > .tab-icon {
      font-size: 1rem;
      color: $dark2;
      padding: 3px 9px;
      border-radius: 3px;
      margin: 1px;
      transition: $generic-transition;
      &:hover {
        color: $dark;
        background-color: $grey2;
      }
    }
  }
}
.layout-forms + .tab-content {
  flex: 0 0 100%;
  height: calc(100% - (145px + 1rem));

  border: $border1;
  padding: 0.7rem 0;
  border-top: none;
  padding: 0.7rem 0.7rem 0;
  > .tab-pane {
    &.active {
      height: 100%;
    }
    > .layout-forms-export-ot {
      > .layout-forms-export-ot-title {
        font-size: 1.2rem;
      }
      > .layout-forms-export-ot-input-wrapper {
        > .layout-forms-export-ot-legend {
          font-size: 0.9rem;
        }
        > .layout-forms-export-ot-input-container {
          margin-top: 0.7rem;
          > label {
            margin-bottom: 0;
          }
          > .react-datepicker-wrapper {
            > .react-datepicker__input-container {
              > input {
                width: 100%;
                border: $border1;
                height: 2rem;
              }
            }
          }
          > select {
            width: 100%;
            border: $border1;
            height: 2rem;
          }
        }
      }

      > .layout-forms-export-ot-action-wrapper {
        display: flex;
        flex-wrap: wrap;
        gap: 5px 10px;
        margin-top: 20px;
        > button {
          flex: 1 0 calc(50% - 10px);
          font-size: 0.8rem;
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 8px 15px;
          border-radius: 0.3rem;
          border: none;
          transition: $generic-transition;
          background-color: $blue2;
          color: $white;
          > [class*="file-export"] {
            margin-right: 5px;
          }
          &:hover,
          &:active,
          &:focus {
            background-color: $blue1;
          }
        }
      }
    }
  }
}
[id^="controlled-ot-tabs-tab-"] {
  padding: 0px;
}
[id^="controlled-ot-tabs-tab-"].active .ot_tab_icon {
  color: var(--darkcolor);
}
