/* ot_comments_list
ot_comment_block
ot_comment_detail
ot_comment_info */

.ot_comments_list {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  padding: 10px 5px;
  /* border: 1px solid var(--darkcolor_soft); */
  border-radius: 0.3rem;
  max-height: 350px !important;
  margin-top: 10px;
  overflow: auto;
  background-color: var(--ltcolor);
}

.ot_comments_list .ot_comment_block {
  flex: 0 0 100%;
  margin-bottom: 15px;
  background-color: #fff;
  padding: 5px 80px 5px 10px;
  display: flex;
  flex-wrap: wrap;
}
.ot_comments_list .ot_comment_block.current_user {
  text-align: right;
  padding: 5px 10px 5px 80px;
}
.ot_comments_list .ot_comment_block .ot_comment_detail {
  flex: 0 0 100%;
}
.ot_comments_list .ot_comment_block .ot_comment_info {
  flex: 0 0 100%;
}
