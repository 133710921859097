.layout-filter {
  display: flex;
  width: 100%;
  > .react-datepicker-wrapper {
    flex: 0 1 85%;
    > .react-datepicker__input-container {
      min-height: 2.4rem;
      height: 100%;
      width: 100%;
      border-top-left-radius: 5px;
      border-radius: 0;
      border: none;
      border-bottom: 1px solid rgba(15, 39, 59, 0.2);
      transition: 0.3s all linear;
      > input {
        height: 100%;
        border: none;
        border-radius: 0;
        padding: 0 32px 0 16px;
        width: 100%;
      }
    }
  }

  > .layout-filter-submit,
  > .layout-filter-undo {
    align-items: center;
    border: none;
    color: #fff;
    display: flex;
    flex: 0 0 15%;
    font-size: 1.2rem;
    height: 32px;
    justify-content: center;
    min-height: 2.4rem;
    text-align: center;
    width: 32px;
    position: relative;
    > i {
      transition: $generic-transition;
      position: absolute;
    }
    > [class*="angle"] {
      right: -10px;
      opacity: 0;
    }
    &:focus,
    &:hover,
    &:active {
      > [class*="search"] {
        opacity: 0;
        transform: translate(-50%, 0);
      }
      > [class*="angle"] {
        opacity: 1;
        right: 50%;
        transform: translate(50%, 0);
      }
      > [class*="undo"] {
        transform: rotate(-360deg);
      }
    }
  }

  > .layout-filter-submit {
    background: $dark;
  }
  > .layout-filter-undo {
    border-radius: 0 5px 5px 0;
    background: $yellow4;
  }
  &:focus {
    color: rgba(15, 39, 59, 0.8);
    border-bottom: 1px solid rgba(15, 39, 59, 0.8);
  }
}
