.ot_watchmaker_repair_modal_top,
.ot_watchmaker_repair_modal_body,
.ot_watchmaker_repair_modal_bottom,
.ot_watchmaker_repair_modal {
  border: unset !important;
}

.ot_watchmaker_repair_modal_top {
  padding: 15px 15px 5px !important;
}

.ot_watchmaker_repair_modal_top > div {
  line-height: 1 !important;
}
.ot_watchmaker_repair_modal_top h6 {
  font-size: 1.5rem;
  width: 100%;
  display: block;
  margin-bottom: 0 !important;
}
.ot_watchmaker_repair_modal_top span {
  display: block;
  font-size: 1rem !important;
  margin-bottom: 0 !important;
}

.ot_watchmaker_repair_modal_top small {
  font-size: 0.6rem !important;
  margin-bottom: 0 !important;
}
.ot_watchmaker_repair_modal_top h6 span {
  font-size: 1rem;
}
.ot_watchmaker_repair_modal_body {
  padding-top: 0;
  padding-bottom: 0;
}

/* SELECCIÓN DE TIPO DE REPARACIÓN */

.ot_watchmaker_repair_list_type {
  display: flex;
  width: 100%;
  padding: 0.3rem;
  border-radius: 0.3rem;
  border: 1px solid var(--input-border);
  justify-content: space-around;
  margin: 0 0 10px;
}

.ot_watchmaker_repair_list_type > div {
  border-radius: 0.3rem;
  margin: 0 10px;
  padding: 5px 10px;
  flex: 0 0 calc(100% / 4 - 15px);
  transition: all 0.3s ease-in-out;
  justify-content: center;
  text-align: center;
}

.ot_watchmaker_repair_list_type > div:hover,
.ot_watchmaker_repair_list_type > div:focus,
.ot_watchmaker_repair_list_type > div:active {
  background-color: var(--input-border);
}

.ot_watchmaker_repair_list_type > div > label {
  margin-bottom: 0;
  margin-left: 10px;
  text-align: left;
}

/* SELECCIÓN DE LISTA REPARACIÓN */
.ot_watchmaker_to_repair_list {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  padding: 0.7rem 0.7rem 0;
  border-radius: 0.3rem;
  border: 1px solid var(--input-border);
}

.ot_watchmaker_to_repair_list > div {
  flex: 0 0 calc(100% / 5 - 20px);
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border-radius: 0.3rem;
  border: 1px solid var(--input-border);
  align-items: center;
  padding: 7px;
  margin-bottom: 10px;
  margin-right: 20px;
  transition: 0.3s all ease-in-out;
}

.ot_watchmaker_to_repair_list > div:hover {
  background-color: var(--input-border);
}

.ot_watchmaker_to_repair_list > div > label {
  margin-bottom: 0;
  margin-left: 10px;
  width: 80%;
}

.ot_watchmaker_to_repair_list > div:last-child,
.ot_watchmaker_to_repair_list > .prep_budget_input_container {
  flex: 1;
  display: flex;
  justify-content: space-between;
}

.ot_watchmaker_to_repair_list > div:last-child > [type="text"] {
  width: calc(100% - 90px);
  border: 1px solid var(--input-border);
}

.ot_watchmaker_to_repair_list > div:last-child > label {
  width: min-content;
  margin-right: auto;
}

/* WARRANTY STATE RADIO BUTTONS */
.ot_watchmaker_warranty_state {
  display: flex;
  width: 35%;
  padding: 0.4rem;
  justify-content: space-between;
  border-radius: 0.3rem;
  border: 1px solid var(--input-border);
}

.ot_watchmaker_warranty_state > div {
  transition: all 0.3s ease-in-out;
  padding: 0 10px;
  flex: 0 0 50%;
  border-radius: 0.3rem;
}

.ot_watchmaker_warranty_state > div:hover {
  background-color: var(--input-border);
}

.ot_watchmaker_warranty_state input {
  margin-right: 10px;
}

/* Styles for input used to derive from watchmaker to watchmaker */
.ot_watchmaker_input_to_derive {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-evenly;
  padding-top: 1rem;
}
.ot_watchmaker_input_to_derive label {
  margin-bottom: 0;
}
.ot_watchmaker_input_to_derive input {
  width: 100%;
  border: 1px solid var(--input-border);
  transition: 0.3s all ease-in-out;
  border-radius: 0.3rem;
}
.ot_watchmaker_input_to_derive input:hover {
  border: 1px solid var(--darkcolor);
}

.ot_watchmaker_derive_buttons {
  display: flex;
  justify-content: space-between;
  flex-flow: row nowrap;
}
.ot_watchmaker_derive_buttons button:first-child {
  flex: 0 0 60%;
}
.ot_watchmaker_derive_buttons button:first-child i {
  margin-right: 7px;
}
.ot_watchmaker_derive_buttons button:last-child {
  flex: 0 0 calc(40% - 15px);
}

.voucher_form {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
}
.voucher_input_container.ot_watchmaker_input_to_derive {
  flex: 0 0 100% !important;
  width: 100%;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;
  position: relative;
}

.voucher_input_container.ot_watchmaker_input_to_derive .generate-id {
  background-color: transparent;
  border: none;
  position: absolute;
  bottom: 0;
  right: 0;
  padding: 0 10px;
  transition: 0.4s all ease-in-out;
  color: var(--darkcolor_soft);
}
.voucher_input_container.ot_watchmaker_input_to_derive .generate-id:hover {
  color: var(--darkcolor);
  padding-right: 15px;
}

.generate-id:hover,
.generate-id:active,
.generate-id:focus {
  outline: none;
}
.voucher_input_container.ot_watchmaker_input_to_derive label {
  display: block;
  width: 100%;
}
.voucher_input_container.ot_watchmaker_input_to_derive > span {
  flex: 0 0 48%;
}

.voucher_input_container.ot_watchmaker_input_to_derive.checkbox {
  justify-content: flex-start;
  align-items: center;
}
.voucher_input_container.ot_watchmaker_input_to_derive.checkbox input {
  margin-right: 5px;
}
.voucher_input_container.ot_watchmaker_input_to_derive.checkbox input,
.voucher_input_container.ot_watchmaker_input_to_derive.checkbox label {
  display: inline;
  width: max-content;
}

.panel_cliente_crear_ticket_inputs .voucher_input_container {
  position: relative;
  margin-bottom: 0.5rem;
  padding-top: 0;
}
.panel_cliente_crear_ticket_inputs .voucher_input_container > input {
  height: 2rem;
  width: 100%;
  border-radius: 0.2rem;
  border: 1px solid var(--input-border);
  outline: none;
  padding-left: 0.5rem;
  transition: 0.3s all linear;
}

.panel_cliente_crear_ticket_inputs .voucher_input_container .generate-id {
  bottom: 10px;
  display: flex;
  align-items: center;
}

.voucher_input_container.ot_watchmaker_input_to_derive.dates {
  display: flex;
}

.voucher_input_container.ot_watchmaker_input_to_derive.dates > small {
  flex: 0 0 100%;
}

.voucher_input_container.ot_watchmaker_input_to_derive.dates > input {
  flex: 0 0 calc(50% - 5px);
}
