.panel_ot_crear_ticket {
  border-radius: 0.3rem;
  display: flex;
  flex-direction: column;
  min-height: 550px;
  width: 100%;
}

.panel_ot_crear_ticket_titulo {
  height: 10%;
  width: 100%;
}

.panel_ot_crear_ticket_titulo > p {
  display: flex;
  align-items: center;
  font-size: 2rem;
  height: 100%;
  margin: 0 1rem;
}

.panel_ot_crear_ticket_formularios {
  align-items: center;
  display: flex;
  justify-content: space-evenly;
  height: 90%;
  width: 100%;
}

.panel_ot_crear_ticket_formularios > div {
  align-items: flex-start;
  border: 1px solid var(--input-border);
  border-radius: 0.2rem;
  display: flex;
  flex: 0 0 24%;
  flex-direction: column;
  height: 100%;
  padding: 0.75rem;
}

.panel_ot_crear_1 h5 {
  margin-bottom: 0.9rem;
}

.panel_ot_crear_1 > p {
  color: var(--darkcolor_soft);
  margin: 1rem 0 0.5rem 0;
}

.panel_ot_create_input_datalist {
  width: 100%;
}

.panel_ot_create_input {
  border-radius: 0.2rem;
  border: 1px solid var(--input-border);
  height: 2.5rem;
  margin-bottom: 0.7rem;
  outline: none;
  padding-left: 0.5rem;
  transition: 0.3s all linear;
  width: 100%;
}

.ot_search_input {
  align-items: center;
  display: flex;
  height: 2.5rem;
  justify-content: space-between;
  margin-bottom: 0.7rem;
  width: 100%;
}

.ot_search_input .panel_ot_create_input {
  flex: 1 0 80%;
  margin-bottom: 0;
}
/*BOTÓN DE BÚSQUEDA DE USUARIO CLIENTE SWATCH POR DNI*/

.ot_search_input .ot_search_user_btn {
  display: inline-block;
  border-radius: 0.2rem;
  background-color: var(--darkcolor_soft);
  border: none;
  color: #ffffff;
  text-align: center;
  font-size: 14px;
  padding: 5px;
  width: 100%;
  height: 100%;
  transition: all 0.5s;
  cursor: pointer;
  margin: 5px;
}

.ot_search_user_btn i {
  cursor: pointer;
  display: inline-block;
  position: relative;
  transition: 0.5s;
}
.ot_search_user_btn i::after {
  content: "\00bb";
  position: absolute;
  opacity: 0;
  top: 0;
  right: -20px;
  transition: 0.5s;
}
.ot_search_user_btn:hover i {
  padding-right: 15px;
}
.ot_search_user_btn:hover i::after {
  opacity: 1;
  right: 0;
}
.ot_search_user_btn:hover {
  background: var(--darkcolor);
  box-shadow: var(--small-shadow);
}

/*BOTÓN DE BÚSQUEDA DE USUARIO CLIENTE SWATCH POR DNI*/

.panel_ot_create_input.product_detail {
  height: 3rem;
  margin-bottom: 0.7rem;
}

.ot_product_detail_fieldset {
  border: 1px solid var(--input-border);
  padding: 0.5rem;
  width: 100%;
}

.ot_product_detail_fieldset:nth-child(3) {
  margin-top: 1rem;
}

.ot_product_detail_fieldset > legend {
  font-size: 0.7rem;
  margin-bottom: 0;
  padding-left: 0.4rem;
  width: 60px;
}

label.ot_has_warranty.detail_1 > small,
label.ot_has_warranty.detail_2 > small,
label.ot_has_warranty.detail_3 > small,
label.ot_has_warranty.detail_4 > small,
label.ot_has_warranty.detail_5 > small,
label.ot_has_warranty.detail_6 > small {
  align-items: center;
  display: flex;
  height: 2.7rem;
  line-height: 1;
  max-width: 40px;
}

.panel_ot_crear_2 > p,
.panel_ot_crear_3 > p,
.panel_ot_crear_4 > p {
  color: var(--darkcolor_soft);
  margin: 0;
}

.panel_ot_create_input:focus,
.panel_ot_create_input:hover,
.ot_has_warranty:hover,
.panel_ot_textarea_diagnostic:focus,
.panel_ot_textarea_diagnostic:hover,
.panel_ot_textarea_observations:focus,
.panel_ot_textarea_observations:hover {
  border: 1px solid var(--darkcolor_soft);
}

.panel_ot_create_input.file {
  padding-top: 0.5rem;
}

.panel_ot_create_input.end_date {
  color: var(--darkcolor_soft);
}

.ot_form_input_checkbox {
  border: 1px solid var(--input-border);
  border-radius: 0.2rem;
  display: flex;
  justify-content: space-between;
  padding: 0.5rem;
  margin-bottom: 1rem;
  width: 100%;
}

.ot_warranty_date {
  justify-content: space-between;
  display: flex;
  width: 100%;
}

.ot_has_warranty {
  align-items: center;
  border: 1px solid var(--input-border);
  border-radius: 0.2rem;
  display: flex;
  flex: 0 0 40%;
  justify-content: center;
  margin-bottom: 1rem;
  transition: 0.2s all linear;
}

.ot_has_warranty > label > input {
  margin-right: 1rem;
}

.ot_warranty_date > input {
  color: var(--darkcolor);
  flex: 0 0 55%;
}

.ot_form_input_checkbox > label {
  flex: 0 0 50%;
  display: flex;
  margin: 0;
  transition: 0.2s all linear;
  padding: 0 0.5rem;
}

.ot_form_input_checkbox > label:hover {
  background: var(--darkcolor_soft);
  border-radius: 0.2rem;
  color: #fff;
}

.ot_form_input_checkbox > label > input,
.ot_has_warranty > input {
  margin-right: 1rem;
}

.panel_ot_textarea_diagnostic,
.panel_ot_textarea_observations {
  border: 1px solid var(--input-border);
  border-radius: 0.2rem;
  height: 5.7rem;
  margin-bottom: 1rem;
  padding: 0.5rem;
  resize: none;
  transition: 0.2s all linear;
  width: 100%;
}

.ot_detail_checkbox {
  align-items: center;
  background: brown;
  display: flex;
  justify-content: center;
  width: 100%;
  height: 2rem;
}

/* BOTONES */

.ot_create_buttons_group {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.ot_create_final_ot_button,
.ot_go_back_n_reset {
  margin: 0.5rem 0;
}

.ot_go_back_n_reset {
  display: flex;
  justify-content: space-between !important;
  width: 100%;
}

.ot_create_final_ot_button,
.ot_go_back_button,
.ot_reset_button {
  border: none;
  border-radius: 0.2rem;
  font-size: 1.4rem;
  color: #fff;
  padding: 0.3rem;
  transition: 0.2s all linear;
}

.ot_create_final_ot_button:hover,
.ot_go_back_button:hover,
.ot_reset_button:hover {
  box-shadow: var(--small-shadow);
}

.ot_go_back_button,
.ot_reset_button {
  flex: 0 0 47%;
}

.ot_create_final_ot_button {
  background: var(--thirdcolor);
}

.ot_go_back_button {
  background: var(--redcolor);
}

.ot_reset_button {
  background: var(--orangecolor2);
}

.ot_create_final_ot_button > i,
.ot_go_back_button > i,
.ot_reset_button > i {
  margin-right: 1rem;
}

.error_in_ot {
  border: 1px double var(--orangecolor2);
  border-radius: 0.2rem;
  color: var(--darkcolor) !important;
  display: flex;
  /* margin-top: 1rem !important ; */
  padding: 0.5rem;
  width: 100%;
  height: 4rem;
}

@import url(./OTConfirmationModal.css);

.create-ot-is-wholesaler {
  align-items: center;
  border: 1px solid var(--input-border);
  border-radius: 0.2rem;
  display: flex;
  flex: 0 0 100%;
  margin-bottom: 1rem;
  transition: 0.2s all linear;
  padding-left: 0.5rem;
  height: 40px;
}

.create-ot-is-wholesaler > input {
  margin-right: 5px;
}
