.pdf_a4_px_size {
	display: flex;
	flex-direction: column;
	width: 794px;
	height: 1123px;
	/* border: 1px solid grey; */
	padding: 100px 60px 60px;
}

.pdf_a4_px_size .header {
	height: 10%;
	display: flex;
	align-items: flex-start;
	justify-content: space-between;
	border-bottom: 1px solid var(--darkcolor_soft);
}

.pdf_a4_px_size .header .header_logo {
	flex: 0 0 40%;
}

.pdf_a4_px_size .header .header_logo img {
	width: 100%;
	height: 100%;
}
.pdf_a4_px_size .header .header_title {
	flex: 0 0 calc(50% - 10px);
	text-align: right;
}
.pdf_a4_px_size .header .header_title strong {
	display: block;
	width: 100%;
}

.pdf_a4_px_size .body {
	display: block;
	padding: 30px 15px;
	border-radius: 0.3rem;
	width: 100%;
	border: 1px solid var(--darkcolor_soft);
	margin: 10px 0;
	height: auto;
}
.pdf_a4_px_size .body .body_client,
.pdf_a4_px_size .body .body_ot {
	flex: 0 0 100%;
	display: flex;
	flex-wrap: wrap;
	align-items: flex-start;
	justify-content: flex-start;
}
.pdf_a4_px_size .body > div > div {
	flex: 0 0 calc(100% / 3);
	margin-bottom: 25px !important;
}
.pdf_a4_px_size .body > div > div p {
	margin-bottom: 0 !important;
	font-weight: bold;
}
.pdf_a4_px_size .body > div > div span {
	padding-left: 10px !important;
}

.pdf_a4_px_size .body .body_client .subtitle,
.pdf_a4_px_size .body .body_ot .subtitle {
	display: block;
	flex: 0 0 90%;
	width: 90%;
	border-bottom: 1px solid var(--darkcolor_soft);
}

.pdf_a4_px_size .body .bigtext {
	flex: 0 0 50%;
}

.pdf_a4_px_size .footer {
	display: flex;
	align-items: flex-start;
	justify-content: space-between;
	padding-top: 20px;
	height: 15%;
	align-self: flex-end;
	width: 100%;
	border-top: 1px solid var(--darkcolor_soft);
	margin-top: auto;
}
.pdf_a4_px_size .footer .footer_created_by {
	display: flex;
	flex-direction: column;
	padding-top: 10px;
}
